import React from 'react'
import BtnSecondary from '../../shared/buttons/BtnSecondary'
import titleLine from '../../assets/titleLine.png'
import titleFrame from '../../assets/titleFrame.png'
import hero from '../../assets/homeschool-hero.png'

export default function Hero() {
    return (
        <div className='mt-8 inner-container flex items-center flex-col md:flex-row'>
            <div className='flex-1 md:block flex items-center flex-col text-center md:text-left'>
                <BtnSecondary text="Homeschool" color="purple" />
                <h3 className='mt-9'>
                    The <div className='relative inline mr-2'>
                        Codekids: Best code kits for
                        <img src={titleLine} alt="pic" className='absolute -bottom-3 right-0' />
                        <img src={titleFrame} alt="pic" className='absolute lg:bottom-8 bottom-1 -right-4 lg:-right-14' />
                        <span className='text-primary'> home</span>
                        <span className='text-orange'>schoolers</span>
                    </div>
                </h3>
                <p className='mt-5 mb-8 body3'>CodeKids teaches kids about STEM and computer science by letting them code their own apps!</p>
                <BtnSecondary text='Sign Up for Free to Try out A CodingKit' type={'button'} />
            </div>
            <div className='flex-1 hidden md:block'>
                <img src={hero} alt="pic" />
            </div>
        </div>
    )
}
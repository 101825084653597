import classNames from 'classnames'
import React from 'react'

export default function Comment({ card, index, theme }) {
    const cardClasses = classNames({
        "grey-card bg-secondary": index % 2 !== 0,
        "yellow-card bg-yellow-light": index % 2===0,
    })

    return (
        <div className={'flex items-center gap-4 max-w-lg w-full py-7 px-6 ' + cardClasses}>
            <img src={card.img} alt="pic" className='md:w-28 w-14 h-14 md:h-28 cover rounded-xl' />
            <div>
                <p className='body2'>
                    {card.text}
                </p>
                <p className='title2 mt-2'>
                    {card.userName}
                </p>
            </div>
        </div>
    )
}
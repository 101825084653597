import React from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import classNames from 'classnames'


export default function LoginModal({ open, setOpen, children, version }) {
    const modalClass = classNames({
        "max-w-620 py-4 px-5": version == 'small',
        "flex max-w-3xl": version != 'small',
        "flex max-w-4xl py-4 px-5": version == 'big',
    })
    return (
        <Transition show={open}>
            <Dialog className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0 z-50 w-screen">
                    <div className="flex h-full items-center justify-center p-4">
                        <div
                            className='fixed z-50 blur top-0 left-0 w-full h-full flex items-center justify-center'></div>
                        <TransitionChild>
                            <DialogPanel className={"relative overflow-hidden bg-white z-50 rounded-20 transform  w-full " + modalClass}>
                                {children}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
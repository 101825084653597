import React from 'react'
import BtnSecondary from './../../shared/buttons/BtnSecondary'
import PrimaryBtn from '../../shared/buttons/BtnPrimary'

export default function ProductsCard({ product }) {
  return (
    <div className='w-full yellow-card bg-white p-4 max-w-72'>
      <img src={product.img} alt="pic" className='rounded-xl mb-7' />
      <BtnSecondary text={product.btnText} />
      <p className='mt-2 title1'>{product.level}</p>
      <p className='body2 mt-3'>{product.text}</p>
      <h5 className='lg:mt-6 lg:mb-7 my-4'>{product.price}</h5>
      <PrimaryBtn text={'Get Started Now'} />
    </div>
  )
}
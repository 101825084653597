import React from 'react'
import line from './../assets/about.png'
import learning from '../assets/leaning1.png'
import curvedLine from '../assets/about-line.png'
import plane from '../assets/plane-model.png'
import curled from '../assets/about-curl.png'

export default function Banner() {
    return (
        <div className='bg-orange relative md:pt-18 pt-12 pb-16 md:pb-28 px-4'>
            <img src={line} alt="img" className="absolute bottom-0 left-0 w-full" />
            <div className='grey-card bg-white inner-container z-40 md:px-20 px-4 relative'>
                <h3 className='text-center text-primary'>
                    At CodeKids, every child will become a creator, not just a consumer.
                </h3>
                <img src={learning} alt="img" className='absolute hidden md:block left-0 bottom-0 w-32' />
            </div>
            <img src={curvedLine} alt="img" className='absolute bottom-8 hidden md:block z-10' />
            <div className='hidden md:block'>
                <img src={curled} alt="img" className='absolute bottom-10 right-1/4' />
                <img src={plane} alt="img" className='absolute bottom-10 right-[20%] z-10' />
            </div>
        </div>
    )
}
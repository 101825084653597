import React from 'react'
import settings from './../assets/setting.png'
import bottomImg from './../assets/setting2.png'
import user from './../assets/user2.png'
import user2 from '../assets/comment1.png'

export default function WhoWeAre() {
    return (
        <div className='bg-light-pink py-16'>
            <div className='inner-container'>
                <h3>WHO WE ARE</h3>
                <div className='md:p-10 p-4 mt-10 yellow-card bg-yellow-light flex items-end gap-20'>
                    <div className='flex-1 relative hidden md:block'>
                        <img src={settings} alt="pic" className='absolute w-16 lg:w-auto -left-10 lg:-top-9 -top-4' />
                        <img src={user} alt="pic" />
                        <img src={bottomImg} alt="pic" className='absolute w-32 lg:w-auto -right-10 bottom-0' />
                    </div>
                    <div className='xl:flex-[2] flex-1'>
                        <img src={user2} alt="img" className='max-w-20' />
                        <h4 className='text-orange'>Myron Murph</h4>
                        <p className='text-primary body2'>-CEO & CO-FOUNDER-</p>
                        <p className="mt-5 body2 text-dark">
                            Myron Murph has been a Software Engineer and Developer for over ten years. He has created applications and provided other IT and developer services for companies of all industries and all sizes, from startups to well-known brands like Nike, Microsoft, Spectrum, and Wells Fargo, and Federal Government agencies like the IRS and Dept. of Veteran Affairs. He has a strong passion for teaching others. Headquartered in North Carolina, CodeKids was born with the belief and passion to help kids worldwide learn and explore the benefits of coding at an early age. In Myron’s free time he loves to spend time with his wife and 2 kids, read the bible, learn more about technology, play sports, and travel around the world.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import Comments from './../components/Comments'
import Contact from './../components/Contact'
import Hero from '../components/HomeSchool/Hero'
import CodeKids from '../components/HomeSchool/CodeKids'
import verticalLines from './../assets/vertical-lines.png'
import Products from './../components/HomeSchool/Products'
import FAQs from '../components/HomeSchool/FAQs'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function HomeSchool() {
    return (
        <>
            <Navbar />
            <div className='relative pb-24'>
                <Hero />
                <img src={verticalLines} alt="pic" className='absolute  -z-10 -top-40 left-0' />
            </div>
            <CodeKids />
            <Products />
            <FAQs />
            <Comments />
            <Contact />
            <Footer />
        </>
    )
}
import React from 'react'
import BtnPrimary from './../shared/buttons/BtnPrimary'
import pay from './../assets/pay.png'
import { Formik } from 'formik'
import Input from './../shared/inputs/Input'
import visa from './../assets/visa.png'
import masterCard from '../assets/mastercard.png'
import Select from '../shared/selects/Select'
import steps from './../assets/more_steps.png'
import back from './../assets/back.png'
import basic from '../assets/basic.png'
import dot from './../assets/dot-gray.png'
import { useNavigate } from 'react-router'

export default function Payment() {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col md:flex-row w-full min-h-screen cont relative'>
      <div className='flex-1 bg-light lg:p-18 sm:p-10  p-4 flex justify-between flex-col gap-10 md:gap-0'>
        <img src={steps} alt="img" className='absolute hidden md:block bottom-0 left-0' />
        <div>
          <div className='flex w-full justify-between pb-4'>
            <button
              onClick={() => {
                navigate('/')
              }}
              className='flex items-center sm:gap-3 gap-1'>
              <img src={back} alt="back" className='sm:w-auto w-4' />
              <p className='title2'>Back</p>
            </button>
          </div>
          <div className='mt-4 bg-white sm:p-5 p-2 rounded-20'>
            <p className='body3'>Course Chosen</p>
            <div className='mt-3 p-3 bg-blue/10 flex items-center justify-between rounded-lg'>
              <img src={basic} alt="img" className='w-20 sm:w-auto' />
              <div className='flex items-center'>
                <h5>$100</h5>
                <p className='body3'>/ per month</p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex body4 text-gray-50 gap-2 justify-end'>
          <p>Power by</p>
          <p className='font-bold'>stripe</p>
          <p>|</p>
          <p>Term</p>
          <img src={dot} alt="img" />
          <p>Privacy</p>
        </div>
      </div>
      <div className='flex-1 lg:p-18 sm:p-10  p-4 flex-col'>
        <button className='p-2 w-full gap-2.5 bg-black text-white body2 rounded-20 flex items-center justify-center'>
          <img src={pay} alt="img" />
          <span>Pay</span>
        </button>
        <div className='flex w-full my-7 gap-2 justify-between items-center'>
          <div className='line'></div>
          <p className='body3 w-full text-gray-50'>Or pay by another way</p>
          <div className='line'></div>
        </div>
        <Formik initialValues={{}}>
          <form>
            <div className='flex flex-col gap-0.5'>
              <label htmlFor="email" className='body2'>Email *</label>
              <Input rounded={'rounded'} value={'tranhuyen@gmail.com'} name="email" />
            </div>
            <div className='flex flex-col gap-0.5 mt-4'>
              <label htmlFor="card" className='body2'>Card Information *</label>
              <div>
                <Input
                  imgs={[visa, masterCard]}
                  rounded={'rounded-t'}
                  placeholder={'Enter your card number'}
                  name="card" />
                <div className='flex'>
                  <Input rounded={'rounded-bl'} placeholder={'Expired Date'} name="date" />
                  <Input rounded={'rounded-br'} placeholder={'CVC'} name="cvc" />
                </div>
              </div>
            </div>
            <div className='flex mt-4 flex-col gap-0.5'>
              <label htmlFor="name" className='body2'>Name on Card *</label>
              <Input rounded={'rounded'} placeholder={'Name on Card'} name="name" />
            </div>
            <div className='flex flex-col gap-0.5 mt-4'>
              <label htmlFor="country" className='body2'>Country or Region *</label>
              <div>
                <Select placeholder={'Select your country'} name="country" />
                <Input rounded={'rounded-bl'} placeholder={'Zip code'} name="zip" />
              </div>
            </div>
          </form>
        </Formik>
        <div className='font-balo mt-7'>
          <BtnPrimary text='Pay $100' />
        </div>
      </div>
    </div>
  )
}
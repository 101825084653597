import React from 'react'
import PrimaryBtn from '../buttons/BtnPrimary'
import classNames from 'classnames'
import { FiCheck } from 'react-icons/fi'

export default function PricingCard({ card }) {
    const themeClasses = classNames({
        'bg-purple text-purple': card.theme==='purple',
        'bg-blue text-blue': card.theme==='blue',
        'bg-green text-green': card.theme==='green',
    })

    return (
        <div className='yellow-card bg-yellow-light md:px-6 px-2 max-w-96 w-full'>
            <img src={card.icon} alt="pic" className='mx-auto' />
            <div className='mx-auto flex justify-center flex-col items-center mt-4'>
                <img src={card.icons[0]} alt="pic" className='-ml-18 -mb-2' />
                {card.price==='Free' ?
                    <h4>{card.price}</h4> :
                    <div className='flex items-end'>
                        <h4>{card.price}</h4>
                        <p className='body4 mb-2'>/ per month</p>
                    </div>
                }
                <img src={card.icons[1]} alt="pic" className='-mt-3' />
            </div>
            <div className='w-48 mx-auto mt-6'>
                <PrimaryBtn text={card.btnText} link={card.link}  />
            </div>
            <div className='bg-white rounded-xl px-5 py-4 mt-6 gap-4 flex flex-col'>
                {card.facilities.map((item, index) => {
                    return <div className='flex items-start gap-2.5 text-dark'>
                        <div className={'w-5 h-5 rounded-full flex items-center justify-center ' + themeClasses}>
                            <FiCheck className='w-5 h-5 p-0.5 text-white text-xl font-bold' />
                        </div>
                        <p className='body3'>{item}</p>
                    </div>
                })}
            </div>

        </div>
    )
}
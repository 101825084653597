import React, { useState } from 'react'
import line from './../../assets/faqs-header.png'
import titleLine from '../../assets/titleLine.png'
import titleFrame from '../../assets/titleFrame.png'
import user from './../../assets/running.png'
import plane from '../../assets/plane-model.png'
import { accordions } from '../../shared/utility'
import Accordion from '../../shared/accordions/Accordion'
import bottomLine from './../../assets/bottom-line.png'

export default function FAQs() {
    const [active, setActive] = useState(0)
    return (
        <div className='relative'>
            <img src={line} alt="pic" className='absolute -top-4 sm:-top-10 2xl:-top-16 3xl:-top-40 w-full' />
            <div className='bg-light-pink h-full md:pt-18 pt-4 md:pb-36 pb-16'>
                <div className='inner-container flex flex-col md:flex-row gap-4 items-center justify-between'>
                    <div className='flex-1'>
                        <h3 className='mt-9 relative'>
                            FAQs About Codekids
                            for {' '}
                            <div className='relative'>
                                <img src={titleLine} alt="pic" className='absolute bottom-0 left-0 w-40 lg:w-96' />
                                <img src={titleFrame} alt="pic" className='absolute sm:bottom-8 bottom-0 lg:right-72 sm:right-28 right-16' />
                                <span className='text-primary'>Home</span>
                                <span className='text-orange'>schoolers</span>
                            </div>
                        </h3>
                        <div className='items-start mt-11 hidden md:flex'>
                            <img src={user} alt="pic" />
                            <img src={plane} alt="pic" />
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-3'>
                        {accordions.map((card, index) => {
                            return <Accordion key={index}
                                active={active}
                                setActive={setActive}
                                index={index}
                                title={card.title}
                                text={card.text} />
                        })}
                    </div>
                </div>
            </div>
            <img src={bottomLine} alt="pic" className='absolute bottom-0 w-full' />
        </div >
    )
}
import React from 'react'
import Contact from './../components/Contact'
import Hero from '../components/Hero'
import PricingComponent from '../components/Pricing/PricingComponent'
import hero from './../assets/pricingHero.png'
import hero2 from './../assets/pricing-hero.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function Pricing() {
    return (
        <>
            <Navbar />
            <Hero imgs={[hero, hero2]}>
                <span className='text-orange'>Our Plans</span> that fit your need
            </Hero>
            <PricingComponent />
            <Contact />
            <Footer />
        </>
    )
}

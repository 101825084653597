import React from 'react'
import line from './../assets/contact-header.png'
import img from './../assets/contactInfo.png'
import Input from '../shared/inputs/Input'
import Textarea from '../shared/inputs/Textarea'
import PrimaryBtn from '../shared/buttons/BtnPrimary'
import { Formik } from 'formik'
import classNames from 'classnames'

export default function Contact({ type, curled }) {
    const divClass = classNames({
        'bg-pink': type !== 'component'
    })
    const typographyClass = classNames({
        'text-primary': type == 'component'
    })
    const contactClass = classNames({
        "pt-4 pb-4": type == 'component',
        "pt-28 pb-24": type != 'component',
    })

    return (
        <div className={'relative z-50 ' + divClass}>
            {type !== 'component' || curled && <img src={line} alt="pic" className='absolute w-full' />}
            <div
                className={'flex z-50 relative items-center flex-col md:flex-row gap-4 inner-container justify-between ' + contactClass}>
                <div className='flex-1'>
                    <h3 className={'text-center md:text-left mx-auto md:mx-0 ' + typographyClass}>
                        Have any questions ? <br />
                        <span className='text-orange'>Contact us now.</span>
                    </h3>
                    <p className='body2 mt-3 max-w-xl text-center md:text-left'>
                        Don’t have Mac or iPad but would like to try our coding kit? Please let us know here and we will contact you with other options</p>
                    <img src={img} alt="pic" className='mt-5 hidden md:block' />
                </div>
                <Formik
                    initialValues={{ name: "", email: '', message: '' }}>
                    <form className='flex-1 w-full md:w-auto flex flex-col gap-4'>
                        <div>
                            <label htmlFor="name" className='font-sans mb-0.5'>Your Name *</label>
                            <Input name="name" placeholder='Type your name' />
                        </div>
                        <div>
                            <label htmlFor="email" className='font-sans mb-0.5'>Email *</label>
                            <Input name="email" placeholder='Type your email' />
                        </div>
                        <div>
                            <label htmlFor="message" className='font-sans mb-0.5'>Message *</label>
                            <Textarea name="message" placeholder='Type your email' />
                        </div>
                        <PrimaryBtn text='Submit' />
                    </form>
                </Formik>
            </div>
        </div>
    )
}
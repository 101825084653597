import React from 'react'
import Contact from './../components/Contact'
import frame from './../assets/frame.png'
import bottomImg from '../assets/contact.png'
import flower from '../assets/flower.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function Contacts() {
  return (
    <>
      <Navbar />
      <div className='bg-primary'>
        <div className='pb-28 md:px-18 px-4 cont relative overflow-hidden'>
          <img src={frame} alt="frame" className='absolute left-0 top-0 w-full h-[140%] object-cover' />
          <div className='bg-white md:mt-18 mt-4 z-50 inner-container p-0 md:p-10 relative rounded-xl md:rounded-[40px]'>
            <Contact type='component' />
          </div>
          <img src={bottomImg} alt="img" className='absolute bottom-0 left-0 cont' />
          <img src={flower} alt="img" className='absolute bottom-6 right-0' />
        </div>
      </div>
      <Footer />
    </>
  )
}
import classNames from 'classnames'
import React from 'react'

export default function BtnSecondary({ text, type, color }) {

    const classes = classNames({
        "title5 lg:px-6 px-3 lg:py-2 py-1 uppercase": type==='button',
        "title2 py-1 px-2.5": type !== 'button',
        'bg-purple': color==='purple'
    })

    return (
        <button className={'rounded-full bg-primary text-white z-40  ' + classes}>
            <p>{text}</p>
        </button>
    )
}
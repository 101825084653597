import React from 'react'
import classNames from 'classnames'
import { Link } from "react-router-dom";

export default function BtnPrimary({ text, type, version, onClick, color, link }) {
    const classes = classNames({
        'border border-dark-blue text-dark-blue ': type === 'outlined',
        'bg-orange text-white': type !== 'outlined',
        "text-sm": version === 'small',
        "bg-primary lg:px-1 xl:px-1": color == 'primary'
    });

    return (
        link ? <Link to={link} rel="noreferrer">
            <button
                className={'rounded-full flex items-center text-sm lg:text-base font-normal z-40 uppercase py-2 px-2 lg:px-6 w-full ' + classes}>
                <span className='text-center w-full'>{text}</span>
            </button>
        </Link> :
        <button onClick={onClick}
            className={'rounded-full flex items-center text-sm lg:text-base font-normal z-40 uppercase py-2 px-2 lg:px-6 w-full ' + classes}>
            <span className='text-center w-full'>{text}</span>
        </button>
    )
}
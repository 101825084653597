import React from 'react'
import BtnPrimary from '../shared/buttons/BtnPrimary'

export default function Welcome({ setEmailOpen }) {
    return (
        <div>
            <p className='title2'>👋 Welcomeback, Tran!</p>
            <p className='mt-8 body2'>
                Please re-enter your email, which will determine which subscription you have
            </p>
            <div className='my-5'>
                <label className='body2' htmlFor="email">Email *</label>
                <input
                    name='email'
                    id='email'
                    type="text"
                    placeholder={'Type your email'}
                    className='py-2 mt-3 px-4 rounded-xl border outline-none w-full border-gray-150 font-sans' />
            </div>
            <BtnPrimary onClick={() => setEmailOpen(false)}
                text='Continue' />
        </div>
    )
}
import React from 'react'
import PricingCard from '../../shared/cards/PricingCard'
import { pricingCards } from '../../shared/utility'
import Title from './../../components/Title'

export default function PricingComponent() {
  return (
    <div className='py-18 inner-container'>
      <h5 className='text-center'>
        Our courses are <span className='text-orange'>expertly-designed</span> to
        <span className='text-primary'> immerse students in each lesson</span>
      </h5>
      <p className='mt-5 body2 text-center'>
        Join the codekids community to take your child's learning to the next level
      </p>
      <div className='my-10 flex-wrap justify-center flex items-center gap-5'>
        {pricingCards.map((card, index) => {
          return <PricingCard key={index} card={card} />
        })}
      </div>
      <Title>
        <div className='md:py-4 md:px-3 px-1 line-clamp-2 overflow-hidden rounded-lg bg-secondary border border-primary max-w-4xl mx-auto'>
          <p className='body2 capitalize'>Our service is month-to-month. You can cancel your subscription at anytime. Our monthly payment system allows for hassle-free cancellation at any time, with guaranteed refunds and subscription termination.
          </p>
        </div>
      </Title>
    </div>
  )
}
import React from 'react'
import leftEffects from './../assets/left-effects.png'
import rightEffects from './../assets/right-effects.png'

export default function Title({ children }) {
    return (
        <div className='flex items-center justify-center gap-4'>
            <img src={leftEffects} alt="pic" className='w-14 md:w-auto'/>
            <h3 className='text-center'>{children}</h3>
            <img src={rightEffects} alt="pic" className='w-14 md:w-auto'/>
        </div>
    )
}
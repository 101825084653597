import React from 'react'

export default function FooterLink({ link }) {
    return (
        <>
            {link.icon ?
                <div className='flex'>
                    <img src={link.icon} alt="pic" />
                    <p className='body2 ml-2'>{link.name}</p>
                </div> :
                <a href={'#'} className='body2 block'>{link.name}</a>
            }
        </>
    )
}
import React from 'react'
import line from './../../assets/products-header.png'
import Title from './../../components/Title'
import { products } from './../../shared/utility'
import ProductsCard from './ProductsCard'
import curledLine from './../../assets/curled-line.png'

export default function Products() {
    return (
        <div className='relative'>
            <img src={line} alt="pic" className='absolute -top-2 sm:-top-4 lg:-top-10 2xl:-top-16 3xl:-top-40 w-full' />
            <div className='bg-pink pt-18 pb-24'>
                <div className='inner-container'>
                    <Title>
                        <span className='text-primary'>Code</span>
                        <span className='text-orange'>kids</span>{' '}
                        Products for Homeschoolers
                    </Title>
                    <div className='flex lg:gap-5 flex-col md:flex-row gap-4 mt-9 justify-center items-center'>
                        {products.slice(0, 4).map((product, index) => {
                            return <ProductsCard key={index} product={product} />
                        })}
                    </div>
                    <img src={curledLine} alt="pic" className='my-9 mx-auto' />
                    <h3 className='text-center'>
                        <span className='text-dark-blue'>EXTRA</span> Goodie</h3>
                    <div className='flex justify-center sm:flex-row flex-col gap-5 mt-9 items-center'>
                        {products.slice(4).map((product, index) => {
                            return <ProductsCard key={index} product={product} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import close from './../assets/close.png'
import { plans } from '../shared/utility'
import PlanCard from '../shared/cards/PlanCard'

export default function ChoosePlan({ setOpen, setPricingOpen }) {
    return (
        <div>
            <div className='flex items-center w-full justify-between'>
                <p className='title2'>👇 Choose the plan you want to continue with</p>
                <button onClick={() => setOpen(false)} className='z-50'>
                    <img src={close} alt="pic" />
                </button>
            </div>
            <div className='mt-8 flex flex-col md:flex-row overflow-auto max-h-[70vh] md:max-h-none items-center gap-3'>
                {plans.map((plan, index) => {
                    return <PlanCard key={index}
                        setOpen={setOpen}
                        index={index}
                        setPricingOpen={setPricingOpen}
                        card={plan} />
                })}
            </div>
        </div>
    )
}
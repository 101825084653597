import classNames from 'classnames'
import React from 'react'

export default function NutshellCard({ card, index }) {
    const { img, highlightedTitle, title, text, additionalImgs } = card

    const cardClasses = classNames({
        "flex-row-reverse md:flex-row-reverse": index % 2 !== 0
    })

    const imgClasses = classNames({
        "sm:-left-8 left-0": index % 2===0,
        "sm:-right-4 right-0": index % 2 !== 0,
    })

    const addImgClasses = classNames({
        'sm:-right-8 right-0': index % 2===0,
        'sm:-left-8 right-0': index % 2 !== 0
    })


    return (
        <div className={'mt-18 items-center flex flex-col md:flex-row gap-4 justify-between ' + cardClasses}>
            <div className='flex-1 relative'>
                <img src={additionalImgs[0]} alt="pic" className={'absolute w-16 lg:w-auto lg:-top-9 -top-4 ' + imgClasses} />
                <img src={img} alt="pic" />
                <img src={additionalImgs[1]} alt="pic" className={'absolute w-32 lg:w-auto bottom-0 ' + addImgClasses} />
            </div>
            <div className='flex-1 flex flex-col items-center'>
                <h4 className='max-w-md w-full'>
                    <span className='text-orange'>{highlightedTitle}</span> {title}
                </h4>
                <p className='body2 mt-7 max-w-md'>
                    {text}
                </p>
            </div>
        </div >
    )
}
import computer from './../assets/computer.png'
import globe from './../assets/globe.png'
import pen from './../assets/pen.png'
import ipad from './../assets/ipad.png'
import apple from './../assets/apple.png'
import cordinates from './../assets/cordinates.png'
import variables from './../assets/variables.png'
import conditional from './../assets/conditional.png'
import student1 from './../assets/person1.png'
import student2 from './../assets/person2.png'
import student3 from './../assets/person3.png'
import familyIcon from './../assets/family.png'
import graduationIcon from './../assets/graduation.png'
import HomeIcon from './../assets/home.png'
import controllerIcon from './../assets/controller.png'
import kid1 from './../assets/kid1.png'
import kid2 from './../assets/kid2.png'
import settings from './../assets/setting.png'
import bottomImg from './../assets/add_img.png'
import settings2 from './../assets/setting2.png'
import bottomImg2 from './../assets/add_img2.png'
import way1 from './../assets/way1.png'
import way2 from './../assets/way2.png'
import way3 from './../assets/way3.png'
import learning1 from './../assets/leaning1.png'
import learning2 from './../assets/learning2.png'
import learning3 from './../assets/learning3.png'
import kids1 from './../assets/kids1.png'
import kids2 from './../assets/kids2.png'
import kids3 from './../assets/kids3.png'
import map from './../assets/map.png'
import mailbox from '../assets/mailbox.png'
import phone from '../assets/phone.png'
import user from './../assets/product.png'
import free from '../assets/free.png'
import basic from './../assets/basic.png'
import premium from './../assets/premium.png'
import topPurple from './../assets/top-purple.png'
import topBlue from './../assets/top-blue.png'
import topGreen from './../assets/top-green.png'
import bottomPurple from './../assets/bottom-purple.png'
import bottomGreen from './../assets/bottom-green.png'
import bottomBlue from './../assets/bottom-blue.png'
import plan_free from '../assets/freemium.png'
import plan_premium from '../assets/plan_premium.png'

export const aboutCards = [
    { title: cordinates, img: student1, additionalImg: computer },
    { title: variables, img: student2, additionalImg: globe },
    { title: conditional, img: student3, additionalImg: pen },
    { title: variables, img: student2, additionalImg: ipad },
    { title: conditional, img: student3, additionalImg: apple },
]

export const links = [
    { name: "Home", href: "/" },
    { name: "Homeschool", href: "/homeschool" },
    { name: "Pricing", href: "/pricing" },
    { name: "FAQs", href: "/faq" },
    { name: "Contact", href: "/contact" },
    { name: "About", href: "/about" },
]

export const facilities = [
    { icon: familyIcon, text: "Kids and Families" },
    { icon: graduationIcon, text: "Schools and libraries" },
    { icon: HomeIcon, text: "Homeschool" },
    { icon: controllerIcon, text: "Game Engine Partners" },
]

export const nutshellData = [
    {
        img: kid1, highlightedTitle: "Why", title: "should kids learn to code?", text: "Each level introduces a new computer science concept with a set of crazyfun app projects that come every month!", additionalImgs: [
            settings, bottomImg
        ]
    },
    {
        img: kid2, highlightedTitle: "What", title: "is CodeKids?", text: "Each level introduces a new computer science concept with a set of crazyfun app projects that come every month!", additionalImgs: [
            settings2, bottomImg2
        ]
    },
    {
        img: kid1, highlightedTitle: "Why", title: "should kids learn to code?", text: "Each level introduces a new computer science concept with a set of crazyfun app projects that come every month!", additionalImgs: [
            settings, bottomImg
        ]
    },
    {
        img: kid2, highlightedTitle: "What", title: "is CodeKids?", text: "Each level introduces a new computer science concept with a set of crazyfun app projects that come every month!", additionalImgs: [
            settings2, bottomImg2
        ]
    },
]

export const howItWorksCards = [
    {
        img: way1, title: <><span className='text-orange'>Kids look through</span> the materials and pick an app.</>, text: "Every box comes with a mix of coding projects, ranging from simple to more advanced."
    },
    {
        img: way2, title: <>Kids type the app into the <span className='text-orange'>CodeKids website.</span></>, text: "Every box comes with a mix of coding projects, ranging from simple to more advanced."
    },
    {
        img: way3, title: <>Kids use and share their apps on <span className='text-orange'> any mobile device.</span></>, text: "Every box comes with a mix of coding projects, ranging from simple to more advanced."
    }
]

export const learningCardData = [
    {
        img: learning1, title: "Code is a language", text: <>Learning to program is just like learning any language. The earlier you start, the easier it is.<span className='text-primary font-bold'> Kids can use CodeKids as soon as they can read and write.</span></>
    },
    {
        img: learning2, title: "Short and sweet", text: <>Learning to program is just like learning any language. The earlier you start, the easier it is.<span className='text-primary font-bold'> Kids can use CodeKids as soon as they can read and write.</span></>
    },
    {
        img: learning3, title: "Monkey see, monkey do", text: <>Learning to program is just like learning any language. The earlier you start, the easier it is.<span className='text-primary font-bold'> Kids can use CodeKids as soon as they can read and write.</span></>
    }
]

export const codeKidsData = [
    {
        img: kids1, text: <>CodeKids is designed for <span className='text-orange'> 6 to 12 year olds</span></>
    },
    {
        img: kids2, text: <>CodeKids is designed for <span className='text-orange'> 6 to 12 year olds</span></>
    },
    {
        img: kids3, text: <>CodeKids is designed for <span className='text-orange'> 6 to 12 year olds</span></>
    },
]

export const comments = [
    { img: user, text: "Wow! Thank you so much! My son had surgery, so he's on very limited activity while he heals. He's spending lots of time on CodeKids code.", userName: "Hilary Grant, Michigan" },

    { img: user, text: "Wow! Thank you so much! My son had surgery, so he's on very limited activity while he heals. He's spending lots of time on CodeKids code.", userName: "Hilary Grant, Michigan" },
]

export const footerLinks = [
    { name: "Home", href: '' },
    { name: "Homeschool", href: '' },
    { name: "Projects", href: '' },
    { name: "FAQs", href: '' },
    { name: "About", href: '' },
    { name: "Dorem ipsum dolor sit amet, consectetur adipiscing elit.", icon: map },
    { name: "123 123 123", icon: phone },
    { name: "codekids@gmail.com", icon: mailbox },
]

export const products = [
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
    {
        img: user, btnText: "CodeKids Coding", level: 'Levels 1-12', price: "$299.95", text: <>
            Includes the <span className='font-bold text-primary'>first twelve levels</span> in the CodeKids coding system.
        </>
    },
]

export const accordions = [
    { title: "1. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
    { title: "2. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
    { title: "3. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
    { title: "4. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
    { title: "5. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
    { title: "6. What kinds of kids can use CodeKids?", text: "We are currently making materials to teach elementary and middle school students about computer science and programming.No previous experience is needed.As a CSforALL member, we are committed to providing quality CS education to every child by creating tools and materials that will make educators' jobs—and their lives—better." },
]

export const pricingCards = [
    {
        theme: 'purple', icon: free, btnText: "Get your free Kits", facilities: [
            '45-minute private Zoom classes, project-based, with an experienced instructor, max two students per class, once a week.', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition'
        ], icons: [topPurple, bottomPurple], price: "Free", link: '#'
    },
    {
        theme: 'blue', icon: basic, btnText: "Subscribe now", facilities: [
            '45-minute private Zoom classes, project-based, with an experienced instructor, max two students per class, once a week.', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition'
        ], icons: [topBlue, bottomBlue], price: "$100", link: '/payment'
    },
    {
        theme: 'green', icon: premium, btnText: "Subscribe now", facilities: [
            '45-minute private Zoom classes, project-based, with an experienced instructor, max two students per class, once a week.', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition', 'Weekly Take Home Activities to encourage Repetition'
        ], icons: [topGreen, bottomGreen], price: "$200",
        link: '/payment'
    },
]

export const plans = [
    { img: plan_free, title: "Get Started with a Free Coding Kit", btnText: "Get logged in now" },
    { img: plan_premium, title: "Explore other pricing options", btnText: "Discover Pricing Plans" },
]
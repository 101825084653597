import React from 'react'
import closeIcon from './../assets/close.png'
import login from './../assets/login-bg.png'
import BtnPrimary from '../shared/buttons/BtnPrimary'

export default function Confirmation({ setOpen, setPlanOpen, setSecondModalOpen, setEmailOpen }) {
    return (
        <div>
            <div className='flex items-center w-full justify-between'>
                <p className='title2'>Do you currently have a subscription with our service?</p>
                <button onClick={() => setOpen(false)} className='z-50'>
                    <img src={closeIcon} alt="pic" />
                </button>
            </div>
            <p className='mt-8 body2'>Please confirm if you have an active subscription with our service. This will help us tailor our support to your needs.</p>
            <div className='mt-5 flex gap-5'>
                <BtnPrimary
                    text="Yes"
                    onClick={() => {
                        setEmailOpen(true)
                        setSecondModalOpen(false)
                    }}
                />
                <BtnPrimary
                    text="No"
                    type="outlined"
                    onClick={() => {
                        setSecondModalOpen(false)
                        setPlanOpen(true)
                    }} />
            </div>
        </div>
    )
}
import classNames from 'classnames'
import React from 'react'

export default function LoginBtn({ icon, text, variant, onClick }) {
    const btnClass = classNames({
        "bg-white border border-primary text-primary": variant === 'outlined',
        "bg-primary text-white": variant !== 'outlined'
    })
    return (
        <button onClick={() => onClick()}
            className={'w-full mt-3 py-2 px-6 rounded-full items-center flex justify-center gap-1 ' + btnClass}>
            <img src={icon} alt="pic" />
            <p className="title5 -mb-1">{text}</p>
        </button>
    )
}
import React from 'react'
import arrow from '../../assets/arrow-down.png'
import classNames from 'classnames'

export default function Accordion({ active, setActive, index, title, text }) {
    const activeClass = classNames({
        'yellow-card bg-yellow-light sm:px-6 px-3': active===index,
        "black-card": active !== index
    })

    const arrowClass = classNames({
        'rotate-180': active===index
    })

    return (
        <div
            className={'cursor-pointer ' + activeClass}
            onClick={() => setActive(index)}>
            <div className='flex items-center justify-between'>
                <p className='body2'>
                    {title}
                </p>
                <img src={arrow} alt="pic" className={arrowClass} />
            </div>
            {active===index && <p className='mt-3 body3'>{text}</p>}
        </div>
    )
}
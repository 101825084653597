import Home from "./pages/Home";
import ComingSoon from "./pages/ComingSoon";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeSchool from "./pages/HomeSchool";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Pricing from "./pages/Pricing";
import FAQ from './pages/FAQ'
import About from "./pages/About";
import Contacts from './pages/Contacts'
import Payment from './pages/Payment'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<ComingSoon />} />
          <Route path="/homeschool" element={<HomeSchool />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/about" element={<About />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

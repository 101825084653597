import React from 'react'
import Comment from './Comment'
import { comments } from '../shared/utility'
import img1 from './../assets/comment1.png'
import img2 from './../assets/comment2.png'

export default function Comments() {
    return (
        <div className='cont md:py-28 py-18 relative px-4'>
            <img src={img1} alt="pic" className='absolute hidden md:block lg:top-14 top-4 left-0' />
            <img src={img2} alt="pic" className='absolute hidden md:block lg:top-14 top-4 right-0' />
            <h3 className='text-center'>
                What <span className='text-orange'>Parents</span> Say About us
            </h3>
            <div className='flex items-center justify-center flex-col md:flex-row gap-7 mt-12'>
                {comments.map((card, index) => {
                    return <Comment key={index} index={index} card={card} theme={'yellow'} />
                })}
            </div>
        </div>
    )
}
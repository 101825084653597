import React from 'react'
import activeBG from './../../assets/active-link.png'
import { useLocation } from 'react-router'

export default function Link({ link }) {
    const location = useLocation()

    const isLinkActive = () => {
        return location.pathname===link.href
    }

    return (
        <a href={link.href} className={'relative flex items-center justify-center ' +
            (!isLinkActive() && 'text-gray')}>
            {isLinkActive() && <img src={activeBG} alt="image" className='absolute md:block hidden' />}
            <h6>{link.name}</h6>
        </a>
    )
}
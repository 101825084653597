import React from 'react'
import back from './../assets/back.png'
import closeIcon from '../assets/close.png'
import { pricingCards } from '../shared/utility'
import PricingCard from './../shared/cards/PricingCard'

export default function PricingPlans({ setOpen, setPlanOpen }) {
  return (
    <div className='w-full'>
      <div className='flex items-center w-full justify-between pb-4'>
        <button onClick={() => {
          setOpen(false)
          setPlanOpen(true)
        }}
          className='flex items-center gap-3'>
          <img src={back} alt="back" />
          <p className='title2'>Back</p>
        </button>
        <button onClick={() => setOpen(false)} className='z-50'>
          <img src={closeIcon} alt="pic" />
        </button>
      </div>
      <div className='xl:max-h-520 max-h-[80vh] overflow-auto'>
        <p className='mt-3 title2'>👇 Choose the plan you want to continue with</p>
        <div className='mt-5 flex flex-col md:flex-row justify-center items-center gap-5'>
          {pricingCards.slice(1).map((card, index) => {
            return <a href='/payment'>
              <PricingCard key={index} card={card} />
            </a>
          })}
        </div>
      </div>
    </div>
  )
}
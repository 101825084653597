import React from 'react'
import plane from '../assets/login-plane.png'
import appleIcon from './../assets/apple-icon.png'
import closeIcon from './../assets/close.png'
import googleIcon from './../assets/google.png'
import login from './../assets/login-bg.png'
import line from './../assets/login-line.png'
import loginImg from './../assets/login.png'
import LoginBtn from './../shared/buttons/LoginBtn'

export default function SignUp({ setOpen, setSecondModalOpen }) {
    return (
        <>
            <img src={login} className='absolute w-full h-full top-0 left-0' />
            <button onClick={() => setOpen(false)} className='z-50'>
                <img src={closeIcon} alt="pic" className='absolute sm:right-10 right-6 md:top-5 top-4' />
            </button>
            <div className='relative md:p-10 p-4 flex md:justify-between justify-center items-center w-full'>
                <div className='md:block hidden'>
                    <img src={loginImg} alt="pic" />
                    <img src={line} alt="pic" className='absolute bottom-0 left-0' />
                </div>
                <div className='yellow-card bg-pink w-80 z-40 relative'>
                    <h6 className='text-primary'>Sign Up</h6>
                    <LoginBtn
                        icon={googleIcon}
                        onClick={() => {
                            setSecondModalOpen(true)
                            setOpen(false)
                        }}
                        text='Continue With Google' />
                    <LoginBtn
                        icon={appleIcon}
                        onClick={() => {
                            setSecondModalOpen(true)
                            setOpen(false)
                        }}
                        text='Continue With Apple'
                        variant={'outlined'} />
                </div>
                <img src={plane} alt="pic" className='absolute md:block hidden bottom-0 right-0 z-0' />
            </div>
        </>)
}
import classNames from 'classnames'
import { Field } from 'formik'
import React from 'react'

export default function Input({ placeholder, name, value, rounded, imgs }) {
    const inpClass = classNames({
        'rounded-xl ': !rounded,
    })
    return (
        <div className='relative w-full'>
            <Field
                name={name}
                id={name}
                defaultValue={value}
                type="text"
                placeholder={placeholder}
                className={'py-2 px-4 border outline-none w-full border-gray-150 font-sans ' + inpClass + rounded} />
            <div className='flex items-center absolute right-3 top-3'>
                {imgs && imgs.map((icon, index) => {
                    return <img src={icon} alt="img" key={index} />
                })}
            </div>
        </div>
    )
}
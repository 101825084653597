import React from 'react'
import BtnPrimary from './../buttons/BtnPrimary'

export default function PlanCard({ card, index, setPricingOpen, setOpen }) {
  return (
    <div onClick={() => {
      if (index == 1) {
        setOpen(false)
        setPricingOpen(true)
      }
    }} className='w-full cursor-pointer py-6 px-7 rounded-20 border border-gray-150'>
      <img src={card.img} alt="img" className='w-full' />
      <p className='mt-4 title1 text-center mb-4'>{card.title}</p>
      <BtnPrimary
        color={index == 1 && "primary"}
        text={card.btnText} />
    </div>
  )
}
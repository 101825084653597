import React, { useState } from 'react'
import logo from './../assets/Logo.png'
import BtnPrimary from './../shared/buttons/BtnPrimary'
import Link from './../shared/links/Link'
import { links } from './../shared/utility'
import LoginModal from './LoginModal'
import { FiMenu } from 'react-icons/fi'
import close from './../assets/close.png'
import SignUp from './SignUp'
import Confirmation from './Confirmation'
import Welcome from './Welcome'
import ChoosePlan from './ChoosePlan'
import PricingPlans from './PricingPlans'

export default function Navbar() {
    const [open, setOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [secondModalOpen, setSecondModalOpen] = useState(false)
    const [emailOpen, setEmailOpen] = useState(false)
    const [planOpen, setPlanOpen] = useState(false)
    const [pricingOpen, setPricingOpen] = useState(false)

    return (
        <div className='flex justify-between items-center inner-container'>
            <img src={logo} alt={logo} className='z-40' />
            <div className='md:flex items-center lg:gap-12 gap-6 hidden'>
                {links.map((link, index) => {
                    return <Link link={link} key={index} />
                })}
            </div>
            <div className='hidden md:block'>
                <BtnPrimary
                    onClick={() => setOpen(true)}
                    text="Log In" />
            </div>
            <button onClick={() => setMenuOpen(true)}
                className='md:hidden text-xl'>
                <FiMenu />
            </button>
            {menuOpen && <div className='fixed bg-white top-0 h-full w-full left-0 z-50'>
                <div className='flex items-center justify-between px-4'>
                    <img src={logo} alt={logo} className='z-40' />
                    <button onClick={() => setMenuOpen(false)}>
                        <img src={close} alt="close" />
                    </button>
                </div>
                <div className='flex flex-col items-start px-4 gap-4'>
                    {links.map((link, index) => {
                        return <Link link={link} key={index} />
                    })}
                    <BtnPrimary
                        onClick={() => setOpen(true)}
                        text="Log In" />
                </div>
            </div>}

            <LoginModal open={open} setOpen={setOpen}>
                <SignUp setOpen={setOpen} setSecondModalOpen={setSecondModalOpen} />
            </LoginModal>
            <LoginModal version='small' open={secondModalOpen} setOpen={setSecondModalOpen}>
                <Confirmation setOpen={setOpen}
                    setEmailOpen={setEmailOpen}
                    setSecondModalOpen={setSecondModalOpen}
                    setPlanOpen={setPlanOpen}
                />
            </LoginModal>
            <LoginModal version='small' open={emailOpen} setOpen={setEmailOpen}>
                <Welcome setEmailOpen={setEmailOpen} />
            </LoginModal>
            <LoginModal version='small' open={planOpen} setOpen={setPlanOpen}>
                <ChoosePlan setOpen={setPlanOpen} setPricingOpen={setPricingOpen} />
            </LoginModal>
            <LoginModal version='big' open={pricingOpen} setOpen={setPricingOpen}>
                <PricingPlans setOpen={setPricingOpen} setPlanOpen={setPlanOpen} />
            </LoginModal>
        </div>
    )
}
import React from 'react'
import line from './../../assets/homeschool-curled.png'
import line2 from './../../assets/homeschool-yellow.png'
import { nutshellData } from './../../shared/utility'
import plane from './../../assets/airplane-white.png'
import curledLine from './../../assets/curledLineWhite.png'
import NutshellCard from '../../shared/cards/NutshellCard'

export default function CodeKids() {
    return (
        <div className="relative lg:mt-28 overflow-hidden text-white">
            <div className='relative h-full'>
                <img src={line} alt="pic" className='w-full top-0 z-20 absolute' />
                <img src={line2} alt="pic" className='absolute -z-10 top-0 w-full' />
            </div>
            <div className='h-full md:pt-40 pt-6 relative'>
                <div className='bg-primary md:pb-32 pb-16'>
                    <div className='inner-container relative z-40'>
                        <img src={plane} alt="pic" className='absolute right-0 z-0 -top-6 hidden md:block' />
                        {nutshellData.map((card, index) => {
                            return <NutshellCard index={index} card={card} key={index} />
                        })}
                    </div>
                    <div className='relative cont'>
                        <img src={curledLine} alt="pic" className='absolute left-0 z-0 hidden md:block -bottom-10' />
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState } from 'react'
import Hero from './../components/Hero'
import Contact from './../components/Contact'
import Accordion from '../shared/accordions/Accordion'
import { accordions } from '../shared/utility'
import hero from './../assets/faq-hero.png'
import hero2 from './../assets/faq-hero2.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function FAQ() {
  const [active, setActive] = useState(0)

  return (
    <div>
      <Navbar />
      <Hero imgs={[hero, hero2]}>
        Frequently <span className='text-orange'>Asked Questions</span>
      </Hero>
      <div className='flex-1 bg-white mt-18 md:mb-40 mb-18 px-4 max-w-4xl mx-auto flex flex-col gap-3'>
        {accordions.map((card, index) => {
          return <Accordion key={index}
            active={active}
            setActive={setActive}
            index={index}
            title={card.title}
            text={card.text} />
        })}
      </div>
      <Contact />
      <Footer />
    </div>
  )
}
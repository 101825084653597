import React from 'react'
import gradient from './../assets/gradient.png'
import hero from './../assets/pricing-hero.png'
import flower from './../assets/flower.png'
import leaf from './../assets/leaf.png'

export default function Hero({ imgs, children }) {
    return (
        <div className='bg-primary'>
            <div className='relative cont sm:h-96 h-60 flex items-end justify-between'>
                <img src={gradient} alt="pic" className='absolute top-0 w-full h-full object-cover left-0' />
                <div className='relative'>
                    <img src={imgs[0]} alt="pic" />
                    <img src={leaf} alt="pic" className='absolute w-full left-0 bottom-0' />
                </div>
                <div className='h-full flex items-center'>
                    <h2 className='text-center text-white z-40'>
                        {children}
                    </h2>
                </div>
                <div className='relative'>
                    <img src={imgs[1]} alt="pic" />
                    <img src={flower} alt="pic" className='absolute right-0 w-12 sm:w-auto bottom-0' />
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import hero from './../assets/faq-hero.png'
import hero2 from './../assets/faq-hero2.png'
import Hero from './../components/Hero'
import Contact from './../components/Contact'
import NutshellCard from './../shared/cards/NutshellCard'
import { nutshellData } from './../shared/utility'
import curledLine from './../assets/curledLine.png'
import airplane from './../assets/airplane.png'
import verticalLines from './../assets/vertical-lines.png'
import WhoWeAre from '../components/WhoWeAre'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function About() {
  return (
    <div>
      <Navbar />
      <Hero imgs={[hero, hero2]}>
        about {' '}<span className='text-orange'>us</span>
      </Hero>
      <div className='md:pt-40 pt-20 relative pb-20 cont'>
        <h3 className='text-center'>
          What {' '}
          <span className='text-primary'>Code</span>
          <span className='text-orange'>Kids</span> {' '} Do
        </h3>
        <div className='inner-container relative'>
          <img src={airplane} alt="pic" className='absolute hidden lg:block right-0 -top-6' />
          {nutshellData.slice(0, 3).map((card, index) => {
            return <NutshellCard index={index} card={card} key={index} />
          })}
        </div>
        <img src={curledLine} alt="pic" className='absolute hidden lg:block left-0 bottom-96 mb-20 -z-10' />
        <img src={verticalLines} alt="pic" className='absolute -z-20 h-full top-0 left-0' />
      </div>
      <WhoWeAre />
      <Banner />
      <Contact curled="none" />
      <Footer />
    </div>
  )
}
import React from 'react'

export default function Select({ name, placeholder }) {
    return (
        <select
            name={name}
            id={name}
            placeholder={placeholder}
            className={'py-2 px-4 text-gray-50 border outline-none w-full border-gray-150 font-sans '} >
            <option value="val1">{placeholder}</option>
        </select>
    )
}
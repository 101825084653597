import React from 'react'

export default function Textarea({ name, placeholder }) {
    return (
        <textarea
            name={name}
            id={name}
            placeholder={placeholder}
            className='py-2 h-52 px-4 rounded-xl border outline-none w-full border-gray-150 font-sans' />
    )
}
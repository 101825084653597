import React from 'react'
import line from '../assets/footer-header.png'
import logo from './../assets/footer-logo.png'
import secondaryLogo from './../assets/add_img.png'
import { footerLinks } from '../shared/utility'
import FooterLink from './../shared/links/FooterLink'
import dot from './../assets/dot.png'
import plane from './../assets/plane.png'

export default function Footer() {
  return (
    <div className='3xl:-mt-36 -mt-10 text-white z-50 relative'>
      <img src={line} alt="pic" className='w-full' />
      <div className='bg-dark-blue h-full z-50 relative'>
        <div className='inner-container py-5 flex flex-col md:gap-0 gap-6 md:flex-row justify-between md:items-center relative lg:pr-28'>
          <div>
            <img src={logo} alt="logo" />
            <img src={secondaryLogo} alt="pic" className='w-32' />
          </div>
          <div>
            <h6>CodeKids</h6>
            <div className='mt-3 flex lg:gap-18 gap-4'>
              <div className='gap-3 flex flex-col'>
                {footerLinks.slice(0, 3).map((link, index) => {
                  return <FooterLink key={index} link={link} />
                })}
              </div>
              <div className='gap-3 flex flex-col'>
                {footerLinks.slice(3, 5).map((link, index) => {
                  return <FooterLink key={index} link={link} />
                })}
              </div>
            </div>
          </div>
          <div>
            <h6>Contact Information</h6>
            <div className='mt-3'>
              <div className='gap-3 flex flex-col'>
                {footerLinks.slice(5).map((link, index) => {
                  return <FooterLink key={index} link={link} />
                })}
              </div>
            </div>
          </div>
          <img src={plane} alt="pic" className='absolute right-0 hidden lg:block -top-9' />
        </div>
        <hr className='border-primary inner-container' />
        <div className='flex items-center flex-col sm:flex-row gap-4 sm:gap-0 justify-between py-10 inner-container'>
          <p className='body2'>2024 © CodeKids Copy Right</p>
          <div className='flex items-center'>
            <FooterLink link={{ name: 'Terms of Service' }} />
            <img src={dot} className='mx-2' alt="pic" />
            <FooterLink link={{ name: 'Privacy Policy' }} />
          </div>
        </div>
      </div>
    </div>
  )
}